import React from 'react';
import HeroContent from 'components/HeroContent';
import { updateTags } from 'util/dom.js';
import { getDataForSlug } from 'util/index.js'; // eslint-disable-line no-unused-vars
import styles from './SchedulePage.module.scss';
import LoadingSpinner from 'components/LoadingSpinner';
import { Redirect } from 'react-router-dom';

const healcodeWidgetHTML =
  '<healcode-widget data-type="schedules" data-widget-partner="object" data-widget-id="047590018dd" data-widget-version="1"></healcode-widget>';

let counter = 0;

export default class SchedulePage extends React.Component {

  render() {
    const data = getDataForSlug('schedule');

    updateTags(data.seoTitle, data.seoDescription, 'schedule');

    if (!window.bwFilterSessions) {
      if (counter < 3) {
        setTimeout(() => {
          counter += 1;
          console.log(`forcing update ${counter}`);
          this.forceUpdate();
        }, 1000);

        return (
          <React.Fragment>
            <HeroContent
              imageUrls={{
                desktop: data.banner.imageDesktop.src,
                mobile: data.banner.imageMobile.src
              }}
              mini>
              {data.banner.title}
            </HeroContent>
            <LoadingSpinner />
            <div
              dangerouslySetInnerHTML={{ __html: healcodeWidgetHTML }}
              className={styles.hide}
            />
          </React.Fragment>
        );
      }
      counter = 0;
      return <Redirect to="/schedules" />
    }
    return (
      <React.Fragment>
        <HeroContent
          imageUrls={{
            desktop: data.banner.imageDesktop.src,
            mobile: data.banner.imageMobile.src
          }}
          mini>
          {data.banner.title}
        </HeroContent>
        <div
          dangerouslySetInnerHTML={{ __html: healcodeWidgetHTML }}
          className={styles.schedulePage}
        />
      </React.Fragment>
    );
  }
}
